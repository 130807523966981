/* You can add global styles to this file, and also import other style files */

body, html, #root {
  font-family: "SFNSText-Regular", Helvetica, Arial, sans-serif;
  background-color: #000;
  color: #efefef;
  height: 100%;
}
  
//   @font-face {
//       font-family: SFNSText-Regular;
//       src: url("../../public/fonts/SFNSText-Regular.otf") format("opentype");
//   }
  
//   @font-face {
//       font-family: SFNSDisplay;
//       font-weight: bold;
//       src: url("../../public/fonts/SFNSText-Regular.otf") format("opentype");
//   }

  * {
    box-sizing: border-box;
  }
  
  
  .p-none { padding: 0 !important; }
  .p-t-none { padding-top: 0 !important; }
  .p-r-none { padding-right: 0 !important; }
  .p-b-none { padding-bottom: 0 !important; }
  .p-l-none { padding-left: 0 !important; }
    
  .p-xsm { padding: 5px; }
  .p-t-xsm { padding-top: 5px; }
  .p-r-xsm { padding-right: 5px; }
  .p-b-xsm { padding-bottom: 5px; }
  .p-l-xsm { padding-left: 5px; }
  
  .p-xsm-r { padding: 2vw; }
  .p-t-xsm-r { padding-top: 2vw; }
  .p-r-xsm-r { padding-right: 2vw; }
  .p-b-xsm-r { padding-bottom: 2vw; }
  .p-l-xsm-r { padding-left: 2vw; }
  
  .p-sm { padding: 10px; }
  .p-t-sm { padding-top: 10px; }
  .p-r-sm { padding-right: 10px; }
  .p-b-sm { padding-bottom: 10px; }
  .p-l-sm { padding-left: 10px; }
  
  .p-sm-r { padding: 3vw; }
  .p-t-sm-r { padding-top: 3vw; }
  .p-r-sm-r { padding-right: 3vw; }
  .p-b-sm-r { padding-bottom: 3vw; }
  .p-l-sm-r { padding-left: 3vw; }     
    
  .p-smd { padding: 12px; }
  .p-t-smd { padding-top: 12px; }
  .p-r-smd { padding-right: 12px; }
  .p-b-smd { padding-bottom: 12px; }
  .p-l-smd { padding-left: 12px; }   
    
  .p-smd-r { padding: 4vw; }
  .p-t-smd-r { padding-top: 4vw; }
  .p-r-smd-r { padding-right: 4vw; }
  .p-b-smd-r { padding-bottom: 4vw; }
  .p-l-smd-r { padding-left: 4vw; }   
    
  .p-md { padding: 15px; }
  .p-t-md { padding-top: 15px; }
  .p-r-md { padding-right: 15px; }
  .p-b-md { padding-bottom: 15px; }
  .p-l-md { padding-left: 15px; }
    
  .p-md-r { padding: 5vw; }
  .p-t-md-r { padding-top: 5vw; }
  .p-r-md-r { padding-right: 5vw; }
  .p-b-md-r { padding-bottom: 5vw; }
  .p-l-md-r { padding-left: 5vw; }
          
  .p-lg { padding: 25px; }
  .p-t-lg { padding-top: 25px; }
  .p-r-lg { padding-right: 25px; }
  .p-b-lg { padding-bottom: 25px; }
  .p-l-lg { padding-left: 25px; }
          
  .p-xl { padding: 35px; }
  .p-t-xl { padding-top: 35px; }
  .p-r-xl { padding-right: 35px; }
  .p-b-xl { padding-bottom: 35px; }
  .p-l-xl { padding-left: 35px; }
  
  .p-lg-r { padding: 8vw; }
  .p-t-lg-r { padding-top: 8vw; }
  .p-r-lg-r { padding-right: 8vw; }
  .p-b-lg-r { padding-bottom: 8vw; }
  .p-l-lg-r { padding-left: 8vw; }
  
  .m-none { margin: 0 !important; }
  .m-t-none { margin-top: 0 !important; }
  .m-r-none { margin-right: 0 !important; }
  .m-b-none { margin-bottom: 0 !important; }
  .m-l-none { margin-left: 0 !important; }
  
  .m-xsm { margin: 5px; }
  .m-t-xsm { margin-top: 5px; }
  .m-r-xsm { margin-right: 5px; }
  .m-b-xsm { margin-bottom: 5px; }
  .m-l-xsm { margin-left: 5px; }
  
  .m-xsm-r { margin: 2vw; }
  .m-t-xsm-r { margin-top: 2vw; }
  .m-r-xsm-r { margin-right: 2vw; }
  .m-b-xsm-r { margin-bottom: 2vw; }
  .m-l-xsm-r { margin-left: 2vw; }
  
  .m-sm { margin: 10px; }
  .m-t-sm { margin-top: 10px; }
  .m-r-sm { margin-right: 10px; }
  .m-b-sm { margin-bottom: 10px; }
  .m-l-sm { margin-left: 10px; }
  
  .m-sm-r { margin: 3vw; }
  .m-t-sm-r { margin-top: 2vw; }
  .m-r-sm-r { margin-right: 2vw; }
  .m-b-sm-r { margin-bottom: 2vw; }
  .m-l-sm-r { margin-left: 2vw; }
  
  .m-smd { margin: 12px; }
  .m-t-smd { margin-top: 12px; }
  .m-r-smd { margin-right: 12px; }
  .m-b-smd { margin-bottom: 12px; }
  .m-l-smd { margin-left: 12px; }
  
  .m-smd-r { margin: 4vw; }
  .m-t-smd-r { margin-top: 4vw; }
  .m-r-smd-r { margin-right: 4vw; }
  .m-b-smd-r { margin-bottom: 4vw; }
  .m-l-smd-r { margin-left: 4vw; }
  
  .m-md { margin: 15px; }
  .m-t-md { margin-top: 15px; }
  .m-r-md { margin-right: 15px; }
  .m-b-md { margin-bottom: 15px; }
  .m-l-md { margin-left: 15px; }
  
  .m-md-r { margin: 5vw; }
  .m-t-md-r { margin-top: 5vw; }
  .m-r-md-r { margin-right: 5vw; }
  .m-b-md-r { margin-bottom: 5vw; }
  .m-l-md-r { margin-left: 5vw; }
  
  .m-lg { margin: 25px !important; }
  .m-t-lg { margin-top: 25px !important; }
  .m-r-lg { margin-right: 25px !important; }
  .m-b-lg { margin-bottom: 25px !important; }
  .m-l-lg { margin-left: 25px !important; }
  
  .m-lg-r { margin: 8vw !important; }
  .m-t-lg-r { margin-top: 8vw !important; }
  .m-r-lg-r { margin-right: 8vw !important; }
  .m-b-lg-r { margin-bottom: 8vw !important; }
  .m-l-lg-r { margin-left: 8vw !important; }
  
  .m-xl { margin: 35px; }
  .m-t-xl { margin-top: 35px; }
  .m-r-xl { margin-right: 35px; }
  .m-b-xl { margin-bottom: 35px; }
  .m-l-xl { margin-left: 35px; }
  
  .m-xl-r { margin: 11vw; }
  .m-t-xl-r { margin-top: 11vw; }
  .m-r-xl-r { margin-right: 11vw; }
  .m-b-xl-r { margin-bottom: 11vw; }
  .m-l-xl-r { margin-left: 11vw; }
    
  .txt-xsm { font-size: 7px; }
  .txt-sm { font-size: 9px; }
  .txt-smd { font-size: 11px; }
  .txt-md { font-size: 13px; }
  .txt-lg { font-size: 17px; }
  .txt-xl { font-size: 25px; }
  .txt-xxl { font-size: 35px; }
    
  .txt-xsm-r { font-size: 2.2vw; }
  .txt-sm-r { font-size: 2.8vw; }
  .txt-smd-r { font-size: 3.45vw; }
  .txt-md-r { font-size: 4.07vw; }
  .txt-lg-r { font-size: 5.32vw; }
  .txt-xl-r { font-size: 7.81vw; }
  .txt-xxl-r { font-size: 10.95vw; }
    
  .txt-align-c {text-align: center !important;}
  .txt-align-l {text-align: left !important;}
  .txt-align-r {text-align: right !important;}
  
  .txt-capitalize {text-transform: capitalize;}
  .txt-uppercase {text-transform: uppercase;}
  .txt-bold {font-weight: bold;}
  .txt-underline { text-decoration: underline; }
  .txt-italic { font-style: italic; }
    
  .txt-valign-m {vertical-align: middle;}
  .txt-valign-b {vertical-align: bottom;}
  .txt-valign-t { vertical-align: top;}
  
    td.b-none, .b-none{ border: none !important;}
    td.t-none, .b-t-none{ border-top: none !important;}
    td.r-none, .b-r-none{ border-right:none !important;}
    td.b-none, .b-b-none{ border-bottom:none !important;}
    td.l-none, .b-l-none{ border-left:none !important;}
    
    .v-align-t {
        vertical-align: top;
    }
    .v-align-b {
        vertical-align: bottom;
    }
    .v-align-m {
        vertical-align: middle;
    }
    .inline-block {
        display: inline-block !important;
    }
    .cursor-pointer {
        cursor: pointer;
    }
  .w-100 {
      width: 100%;
  }
  .w-50 {
      width: 50%;
  }
  .w-25 {
      width: 25%;
  }
  .w-75 {
      width: 75%;
  }
  .w-33 {
      width: 33%;
  }
  
  .border-black { border: 1px solid #000; }
  .border-top-black { border-top: 1px solid #000; }
  .border-right-black { border-right: 1px solid #000; }
  .border-bottom-black { border-bottom: 1px solid #000; }
  .border-left-black { border-left: 1px solid #000; }
  
  
  .display-none {display: none;}
  .display-block {display: block;}
  .display-inline-block {display:inline-block;}
  .position-fixed {position: fixed;}
  .position-relative {position: relative;}
  .position-absolute {position: absolute !important;}
  
  .float-left{ float: left;}
  .float-right{ float: right;}
  
  .clickable {
      cursor: pointer !important;
  }
  .grabbable {
      cursor: move;
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;
  }
  
  .v-align-t {vertical-align: top; }
  .v-align-b {vertical-align: bottom;}
  .v-align-m {vertical-align: middle;}
  .inline-block {display: inline-block !important;}
  .cursor-pointer {cursor: pointer;}
   
  
  .w-100 {width: 100%;}
  .w-98 {width: 98%;}
  .w-97 {width: 97%;}
  .w-95 {width: 95%;}
  .w-90 {width: 90%;}
  .w-75 {width: 75%;}
  .w-50 {width: 50%;}
  .w-48 {width: 48%;}
  .w-25 {width: 25%;}
  .w-20 {width: 25%;}
  .w-75 {width: 75%;}
  .w-33 {width: 33%;}
  .w-31 {width: 31%;}
  .h-100 {height: 100%;}
  
  .border-black { border: 1px solid #000; }
  .border-top-black { border-top: 1px solid #000; }
  .border-right-black { border-right: 1px solid #000; }
  .border-bottom-black { border-bottom: 1px solid #000; }
  .border-left-black { border-left: 1px solid #000; }
  
  .display-none {display: none;}
  .display-block {display: block;}
  .display-inline-block {display:inline-block;}
  .display-flex { display: flex;}
  .display-table-cell {display: table-cell;}
  .display-table {display: table; height: 100%;}
  .flex-direction-row { flex-direction: row;}
  .flex-direction-column { flex-direction: column;}
  .display-flex { display: flex;}
  .position-fixed {position: fixed;}
  .position-relative {position: relative;}
  .position-absolute {position: absolute !important;}
  
  .float-left{ float: left;}
  .float-right{ float: right;}
  
  .justify-content-sb { justify-content: space-between;}
  .justify-content-sa { justify-content: space-around;}
  .justify-content-se { justify-content: space-evenly;}
  .justify-content-l { justify-content: left;}
  .justify-content-r { justify-content: right;}
  .justify-content-c { justify-content: center;}
  
  .align-items-c {align-items: center;}
  
  [hidden] {
      display: none !important;
  }
  
  
  html, body { height: 100%; }
  body { margin: 0; font-family: "SFNSText-Regular", Helvetica, Arial, sans-serif;}
  
  .MuiTypography-root .svg-inline--fa{
    height: 2em;
  }

  .MuiTableCell-root .MuiTouchRipple-root{
    display: none;
  }

  .MuiButton-outlinedPrimary{
    &:hover{
      cursor: pointer;
    }
  }

  .fa-chevron-down{
    transition: transform .3s;
  }

  .MuiInputBase-input.MuiInput-input{
    padding: 4px 0px 4px 10px;
    border-radius: 3px;
  }
  .MuiInputBase-root-MuiInput-root::before{
    border:none !important;
  }
  .MuiInputBase-root-MuiInput-root::after{
    border:none !important;
  }

  .MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd{
    &:hover{
      opacity: .5;
    }
  }

  // .MuiChartsAxis-tickLabel {
  //   width:500px !important;
  //   padding-left: 100px !important;
  // }