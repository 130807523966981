body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  

.app-container{
    display: flex;
    flex-direction: column;
    height: 100%;
}

.top-panel {
    height: 80px;
    display: flex;
    width: 100%;
}

.bottom-panel{
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-grow: 1;
}

.main-panel{
    background-color: steelblue;
    width: 100%;
}

// <div className={styles['app-container']}>
// <div className={styles['top-panel']}>
//     <HeaderComponent title={'HeaderComponent'} />
// </div>
// <div className={'bottom-panel'}>
//     <div className={'side-panel'}>
//         <SideBarComponent title={'SideBarComponent'} />
//     </div>
//     <div className={'main-panel'}>
//         <MainComponent title={'MainComponent'} />
//     </div>
// </div>
// </div>