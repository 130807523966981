.header-container {
    background-color: #0f1b5e;
    width: 100%;
    display: flex;
    &.dashboard {
        background-color: transparent;
    }
}

.logo-panel {
    width: 120px;
    height: 80px;
    padding: 8px 10px 10px 10px;
    text-align: center;
    justify-content: center;
    position: relative;
    cursor: pointer;

    &:hover, &.active {
        background-color: #63677e;
    }

    img {
        height: 45px;

        &:hover {
            // filter: invert(42%) sepia(97%) saturate(1707%) hue-rotate(88deg) brightness(100%) contrast(111%);
        }
    }

    span {
        color: whitesmoke;
        display: block;
        font-size: 12px;
        padding: 2px 7px 0px 7px;
        text-align: center;
    }
}

.right-panel{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    
    .info-panel{
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        div{
            font-size: 35px;
        }
    }
    .nav-panel{
        width: 170px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        div{
            width: 100%;
            padding: 12px 10px 0px 10px;
            text-align: center;
            span{
                font-size: 12px;
                display: block;
                padding-top: 7px;
            }
            &:hover, &.active{
                background-color: #63677e;
                cursor: pointer;
            }

        }
        img{
            height: 35px;
        }
    }
}