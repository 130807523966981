.dashboard-container {
    background-color: black;
    width: 100%;
    height: 100%;
}
.map-container {
    width:40%;
    min-height: 200px;
    padding: 9px 25px 25px 25px;
    h3 {
        font-size: 24px;
    }
}
.traces-container {
    width:55%;
    min-height: 200px;
    padding:25px;
}

.district-container {
    width:27%;
    min-height: 200px;
    padding: 23px 0px 0px 0px;
    margin-bottom: 25px;
    text-align: center;
    h3 {
        font-size: 24px;
    }
}
.valuable-container {
    width:70%;
    min-height: 200px;
    padding: 25px;
    margin-bottom: 25px;
}