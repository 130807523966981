.side-bar{
    width: 250px;
    height: 100%;
    background-color: #0f1b5e;
    display: flex;
    flex-direction: column;

    .side-bar-container{
        display: flex;
        flex-direction: column;

        .side-bar-inner-container{
            display: flex;
            flex-direction: column;
            align-items: center;

            .title{
                width:100%;
                text-align: center;
                margin-bottom: 0px;
                margin-top: 2px;
            }
            
            .checkbox-list {
                list-style: none;
                padding: 0;
                margin: 0;
                width: 100%;
                li{
                    padding: 0;
                    width: 100%;
                }

            }

            .menu {
                list-style: none;
                padding: 0;
                width: 100%;
                .danger{
                    background-color: #990000;
                    cursor: not-allowed;
                    &:hover{
                        background-color: white;
                        color: #990000;
                    }
                }
                li{
                    padding: 15px;
                    width: 100%;
                    &:hover{
                        background-color: #63677e;
                    }
                    &.active{
                        background-color: #63677e;
                    }
                }
            }
        }
    }
}

.accHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 7px;
    &:hover{
        opacity: .5;
        cursor: pointer;
    }
    border-bottom: 1px solid #efefef;
}

.accBody {
    overflow: hidden;
    max-height: 0;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    box-sizing: border-box;
    &.expanded{
        max-height: 800px;
        margin-bottom: 25px;
    }
}
